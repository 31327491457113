// NPM Requirements
import _ from 'lodash';

// Misc Requirements
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { foodCategorySchema, foodCategoryListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('foodCategories', 'foodCategory');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'food_category',
  listName: 'food_categories',
  apiEndpoint: 'food_categories',
  resourceSchema: foodCategorySchema,
  listSchema: foodCategoryListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {};

// Merge Actions
const actions = _.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
