// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Triple.module.postcss';

class Triple extends Component {
  render() {
    const { image, text, title, link } = this.props;
    let imgStyle = { backgroundImage: `url("${image}")` };

    if (link) {
      return (
        <div className={styles.Triple}>
          <Link
            to={link}
          >
            <div style={imgStyle} />
            <h6>{title}</h6>
            <p>{text}</p>
          </Link>
      </div>
      );
    }

    return (
      <div className={styles.Triple}>
        <div style={imgStyle} />
        <h6>{title}</h6>
        <p>{text}</p>
      </div>
    );
  }
}

Triple.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Triple;
