// NPM Requirements
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import styles from '../../../stylesheets/public/components/List.module.postcss';

const CITIES_SEATTLE = [
  'Algona',
  'Auburn',
  'Beaux Arts Village',
  'Bellevue',
  'Black Diamond',
  'Bothell',
  'Burien',
  'Carnation',
  'Clyde Hill',
  'Covington',
  'Des Moines',
  'Duvall',
  'Enumclaw',
  'Federal Way',
  'Hunts Point',
  'Issaquah',
  'Kenmore',
  'Kent',
  'Kirkland',
  'Lake Forest Park',
  'Maple Valley',
  'Medina',
  'Mercer Island',
  'Milton',
  'Newcastle',
  'Normandy Park',
  'North Bend',
  'Pacific',
  'Redmond',
  'Renton',
  'Sammamish',
  'SeaTac',
  'Seattle',
  'Shoreline',
  'Skykomish',
  'Snoqualmie',
  'Tukwila',
  'Woodinville',
  'Yarrow Point'
];

const CITIES_PORTLAND = [
  'Amity',
  'Banks',
  'Barlow',
  'Battle Ground',
  'Beaverton',
  'Camas',
  'Canby',
  'Carlton',
  'Clatskanie',
  'Columbia City',
  'Cornelius',
  'Dayton',
  'Dundee',
  'Durham',
  'Estacada',
  'Fairview',
  'Forest Grove',
  'Gaston',
  'Gladstone',
  'Gresham',
  'Happy Valley',
  'Hillsboro',
  'Johnson City',
  'King City',
  'La Center',
  'Lafayette',
  'Lake Oswego',
  'Maywood Park',
  'McMinnville',
  'Milwaukie',
  'Molalla',
  'Newberg',
  'North Bonneville',
  'North Plains',
  'Oregon City',
  'Portland',
  'Prescott',
  'Rainier',
  'Ridgefield',
  'Rivergrove',
  'Sandy',
  'Scappoose',
  'Sheridan',
  'Sherwood',
  'St. Helens',
  'Stevenson',
  'Tigard',
  'Troutdale',
  'Tualatin',
  'Vancouver',
  'Vernonia',
  'Washougal',
  'West Linn',
  'Willamina',
  'Wilsonville',
  'Wood Village',
  'Woodland',
  'Yacolt',
  'Yamhill'
];

class CityList extends Component {
  render() {
    const { cityConfig } = this.props;

    let cityList = CITIES_SEATTLE;
    if (cityConfig.city_name === "Portland") {
      cityList = CITIES_PORTLAND;
    }

    return (
      <div>
        <h4>Popular Cities</h4>
        <div className={styles.List_items}>
          {cityList.map((city, index) => (
            <Link
              className={styles.List_item}
              key={city + '-' + index}
              to={`/search?day=today&location=${city.split(" ").join("-").toLowerCase()}`}
            >
              Food {cityConfig.vehicle_type}s in {city}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default CityList;
