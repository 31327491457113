import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Hero.module.postcss';

class Hero extends Component {
  render() {
    const { children, photo } = this.props;

    return (
      <section className="PaddedSection">
        <div className="Container">
          <div className={styles.Hero}>
            <div className={styles.Hero_content}>
              {children}
            </div>
            <div className={styles.Hero_photo}>
              <div className={styles.Hero_imageContainer}>
                <div
                  className={styles.Hero_image}
                  style={{ backgroundImage: `url("${photo}")` }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Hero.propTypes = {
  children: PropTypes.node,
  photo: PropTypes.string,
};

export default Hero;
